body {
  margin: 0;
  padding: 0;
  height: -webkit-fill-available;
}
#app {
  display: flex;
  flex-direction: column-reverse;
  height: -webkit-fill-available;
}
#fixed {
  flex: none;
  background: #fff;
  border-top: 1px solid #eee;
  padding: 1em;
  user-select: none;
  -webkit-user-select: none;
  cursor: default;
}
#fixed.recording {
  background: #f00;
  color: #fff;
}
#logo {
  float: left;
}
h1 {
  font-weight: 100;
  margin: 0;
}
#controls {
  margin-bottom: 1em;
  user-select: none;
  -webkit-user-select: none;
  color: #666;
}

#main {
  flex: 1;
  padding: 0.8em;
  overflow-y: scroll;
}
.transcribed,
.response {
  white-space: pre-line;
}
.entry > p {
  margin-top: 0;
  margin-bottom: 1em;
}
.entry > p:last-child {
  margin-bottom: 0;
}
.actions {
  user-select: none;
  -webkit-user-select: none;
  margin-bottom: 0.3em;
}

.actions a {
  text-decoration: none;
}
* {
  font-family: helvetica;
}

.no-transcription {
  color: #999;
}

#results {
  max-width: 60rem;
  line-height: 1.4rem;
}

.entry {
  margin-bottom: 0.3em;
  border: 1px solid #eee;
  padding: 0.4em;
  border-radius: 5px;
}
